/* Give Navbar smooth expand/collapse */
.navbar-collapse {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

/* Remove Border from Toggler */
.navbar-toggler {
    border: 0 !important;
    padding: 1px 6px;
}

.navbar-toggler:focus,
.navbar-toggler:focus,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 0 !important;
}

/* Lines of Toggler */
.toggler-icon {
    width: 30px;
    height: 3px;
    background-color: #e74c3c;
    display: block;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

/* Add Space between Lines */
.middle-bar {
    margin: 5px auto;
}

/* ANIMATED X EXPANDED */
.navbar-toggler .top-bar {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 10% 10%;
    -ms-transform-origin: 10% 10%;
    transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
    opacity: 0;
    filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 10% 90%;
    -ms-transform-origin: 10% 90%;
    transform-origin: 10% 90%;
}

/* ANIMATED X COLLAPSED */
.navbar-toggler.collapsed .top-bar {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
    opacity: 1;
    filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}
/* END ANIMATED X */

/* Colour of Toggler when Collapsed */
.navbar-toggler.collapsed .toggler-icon {
    background-color: #332d2d;
}