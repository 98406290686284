/* Experience Section */
@media screen and (max-width: 768px) {
    #experience {
        padding-top: 96px;
    }

    #experience > .align-items-center {
        align-items: normal !important;
    }
}

.svg-inline--fa {
    width: 64px;
    height: 64px;
    margin: 16px;
    color: #332d2d;
    transition: all 0.2s ease !important;
}

@media screen and (max-width: 992px) {
    .svg-inline--fa {
        flex-basis: 20%;
    }
}

.svg-inline--fa:hover {
    transform: scale(1.2);
}

#panel-swapper {
    cursor: pointer;
}

#second-panel{
    opacity: 0;
    transition: 1s all ease;
}

#second-panel.active{
    opacity: 1;
}

#spray {
    width: 27rem;
    max-width: 100vw;
}

#card-left, #card-right {
    cursor: pointer;
}