/* Projects Section */
@media screen and (max-width: 768px) {
    #projects {
        padding-top: 96px;
    }

    #projects > .align-items-center {
        align-items: normal !important;
    }
}

.titlecard {
    border-right: thin solid #332d2d;
}

#board {
    width: 20vw;
}

@media screen and (max-width: 1200px) {
    #board {
        width: 30vw;
    }
}

@media screen and (max-width: 992px) {
    #board {
        width: 40vw;
    }
}

@media screen and (max-width: 768px) {
    #board {
       width: 50vw;
    }

    #game {
        padding-bottom: 3rem;
    }

    .titlecard {
        border-bottom: thin solid #332d2d;
        border-right: none;
    }
}