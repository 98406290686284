/* Title Section */
#name {
    font-size: 10vw;
    line-height: 1em;
}

@media screen and (max-width: 1200px) {
    #name {
        font-size: 15vw;
    }
}

@media screen and (max-width: 992px) {
    #name {
        font-size: 20vw;
    }
}

@media screen and (max-width: 768px) {
    #name {
        font-size: 25vw;
    }
}

/* Text Cursor Animation */
.blink {
    animation: blinker 1s step-end infinite;
    margin-left: 4px;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}