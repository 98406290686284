/* About Section */
@media screen and (max-width: 768px) {
    #about {
        padding-top: 96px;
    }

    #about > .align-items-center {
        align-items: normal !important;
    }
}

#about-list li {
    text-align: start;
    padding: 16px;
}

@media screen and (max-width: 768px) {
    #about-list li {
        padding: 8px;
    }
}

#about-square {
    width: 80vh;
    height: 80vh;
    margin: auto;
    z-index: -1;
}

#about-square p {
    width: 70vh;
    text-align: center;
    position: absolute;
    transition: all 1s ease !important;
}

#about-square p:nth-child(1) {
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
}

#about-square p:nth-child(2) {
    top: 0;
    bottom: 0;
    left: 7%;
    margin: auto;
    transform: rotate(-90deg);
}

#about-square p:nth-child(3) {
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(180deg);
}

#about-square p:nth-child(4) {
    top: 0;
    bottom: 0;
    right: 7%;
    margin: auto;
    transform: rotate(90deg);
}

#rotator:hover {
    cursor: pointer;
}
