/* Body */
body {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    color: #332d2d;
    background-color: cornsilk;
    -ms-overflow-style: none;
    scrollbar-width: none;
    animation: 2s entrance;
}

body::-webkit-scrollbar {
    display: none;
}

@keyframes entrance {
    from {transform: translateX(-100%); opacity: 0;}
    to {opacity: 1;}
}

/* General */
.ease-1s {
    transition: all 1s ease !important;
}

.center {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.center-alt {
    position: absolute;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

}

.off-center {
    position: absolute;
    margin: auto;
    top: 50%;
    left: 70%;
    transform: translate(-50%,-50%);
}

.back {
    z-index: -1;
}

.fill-height{
    min-height: 100vh;
 }

.marginbtm {
    margin-bottom: 72px;
}

@media screen and (max-width: 1200px) {
    .marginbtm {
        margin-bottom: 64px;
    }
}

@media screen and (max-width: 992px) {
    .marginbtm {
        margin-bottom: 48px;
    }
}

@media screen and (max-width: 768px) {
    .marginbtm {
        margin-bottom: 32px;
    }
}

.bi {
    fill: #332d2d;
}

.card {
    border: thin solid #332d2d;
}

.bg-blur {
    background-color: rgb(255, 255, 255, 0.2);
    backdrop-filter: blur(16px);
}

.bg-fade {
    background-image: linear-gradient(transparent,white);
}

.drop-shadow {
    filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.4));
}

/* Fonts */
.lora {
    font-family: "Lora", serif;
    font-weight: 700;
    font-style: italic;
}

.code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: smaller;
}

.cursive {
    font-family: "Cedarville Cursive", cursive;
    font-size: larger;
}

/* Fade In Animation on Scroll */
.reveal{
    position: relative;
    transform: translateX(-100%);
    opacity: 0;
    transition: 1s all ease;
}

.reveal.active{
    transform: translateX(0);
    opacity: 1;
}

/* Intro */
.disable-scroll {
    height: 100%;
    overflow: hidden;
}

.hidden {
    opacity: 0;
}